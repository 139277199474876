import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "p-text-bold",
  style: {"font-size":"18px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_ActivityForm = _resolveComponent("ActivityForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Button, {
      class: _normalizeClass(_ctx.rounded ? 'p-button-rounded p-mb-2' : 'p-mt-2'),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:dialog', true))),
      icon: "pi pi-plus",
      label: _ctx.withLabel ? _ctx.$t('activity.create.btn') : ''
    }, null, 8, ["class", "label"]),
    (_ctx.dialog)
      ? (_openBlock(), _createBlock(_component_ActivityForm, {
          key: 0,
          modelValue: _ctx.dialog,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:dialog', $event))),
          loading: _ctx.loading,
          onSubmit: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('submit', $event)))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("activity.create.title")), 1),
              _createElementVNode("small", null, _toDisplayString(_ctx.$t("activity.create.subtitle")), 1)
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "loading"]))
      : _createCommentVNode("", true)
  ], 64))
}