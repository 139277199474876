
import { defineComponent, defineAsyncComponent } from "vue";
export default defineComponent({
  name: "CreateActivity",
  components: {
    ActivityForm: defineAsyncComponent(() => import("./ActivityForm.vue")),
  },
  props: {
    rounded: Boolean,
    withLabel: Boolean,
    loading: Boolean,
    dialog: Boolean,
  },
  emits: ["submit", "update:dialog"],
});
